// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apnoe-js": () => import("./../src/pages/apnoe.js" /* webpackChunkName: "component---src-pages-apnoe-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-relaqua-center-trainer-js": () => import("./../src/pages/relaqua-center-trainer.js" /* webpackChunkName: "component---src-pages-relaqua-center-trainer-js" */),
  "component---src-pages-seatrekking-js": () => import("./../src/pages/seatrekking.js" /* webpackChunkName: "component---src-pages-seatrekking-js" */),
  "component---src-templates-becometrainer-js": () => import("./../src/templates/becometrainer.js" /* webpackChunkName: "component---src-templates-becometrainer-js" */),
  "component---src-templates-becometrainerrealqua-js": () => import("./../src/templates/becometrainerrealqua.js" /* webpackChunkName: "component---src-templates-becometrainerrealqua-js" */),
  "component---src-templates-event-js": () => import("./../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-js": () => import("./../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-relaqua-js": () => import("./../src/templates/relaqua.js" /* webpackChunkName: "component---src-templates-relaqua-js" */),
  "component---src-templates-relaquacenter-js": () => import("./../src/templates/relaquacenter.js" /* webpackChunkName: "component---src-templates-relaquacenter-js" */),
  "component---src-templates-training-js": () => import("./../src/templates/training.js" /* webpackChunkName: "component---src-templates-training-js" */)
}

